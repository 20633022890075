import React, { useEffect, useState } from "react"
import useFetch from "../../../lib/useFetch"
import { CheckIcon, DeclineIcon } from "../../../assets/SvgIcons"
import { useHistory } from "react-router-dom"
import { Button, Card, CardContent, CardHeader, Link } from "@material-ui/core"
import styles from "./ConfirmAccount.module.scss"

const ConfirmAccount = () => {
  const [fetchHook] = useFetch()

  const [success, setSuccess] = useState(true)
  const [message, setMessage] = useState("")
  const [user, setUser] = useState<any>()

  let search = window.location.search
  let params = new URLSearchParams(search)

  const history = useHistory()

  useEffect(() => {
    confirmAccount()
  }, [])

  const { workspace, email, confirmationToken } = getQueryParams(params)

  const confirmAccount = async () => {
    const values = {
      slug: workspace,
      email,
      confirmationToken,
    }
    const response = await fetchHook({
      path: `api/confirm-account`,
      method: "PUT",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        "X-TENANT-ID": workspace,
      },
    })

    if (response?.success) {
      setSuccess(true)
      setMessage("Your account has been confirmed.")
      setUser(response?.payload)
    } else {
      setSuccess(false)
      setMessage("Please reach us at info@onegate.app")
    }
  }

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <CardHeader title={`${success ? "Account Confirmed" : "Oops! Something went wrong."}`} />
        <div className={styles.icon}>
          {success && <CheckIcon />}
          {!success && <DeclineIcon />}
        </div>
        <CardContent className={styles.content}>{message}</CardContent>
        <div className={styles.center}>
          {user?.admin && (
            <Button
              type="submit"
              size="large"
              // variant="default"
              color="primary"
              style={{
                width: "100%",
              }}
              onClick={() => {
                history.push("/login")
              }}
            >
              Continue
            </Button>
          )}
        </div>
      </Card>
    </div>
  )
}

export default ConfirmAccount

function getQueryParams(params: any): any {
  return {
    workspace: params.get("workspace"),
    email: params.get("email"),
    phone: params.get("phone"),
    confirmationToken: params.get("confirmationToken"),
  }
}
