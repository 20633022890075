import React, { Suspense } from "react"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import "./App.css"
// import Layout from "./components/Layout/Layout"
import Login from "./components/Auth/Login/Login"
import Register from "./components/Auth/Register/Register"
import ResetPassword from "./components/Auth/ResetPassword/ResetPassword"
import SetPassword from "./components/Auth/SetPassword/SetPassword"
import ConfirmAccount from "./components/Auth/ConfirmAccount/ConfirmAccount"
import DownloadApps from "./components/Auth/DownloadApps/DownloadApps"
import PasswordSetSuccessfully from "./components/Auth/PasswordSetSuccessfully/PasswordSetSuccessfully"
import { useStore } from "./store/store"
import { ThemeProvider } from "@material-ui/core/styles"
import createMyTheme from "./assets/theme"
import { CssBaseline } from "@material-ui/core"
import Snackbar from "./common/Snackbar/Snackbar"
import Modal from "./common/Modal/Modal"
import OActivityIndicator from "./common/ActivityIndicator/OActivityIndicator"

/* Lazy load component: https://reactjs.org/docs/code-splitting.html
   This makes login/register and other pages (before user has authenticated) load much faster
   Also, it delays socket.io connection until the user has been logged in
*/
const Layout = React.lazy(() => import("./components/Layout/Layout"))

const App = () => {
  const { state } = useStore()
  const { user, token } = state

  const theme = createMyTheme()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Suspense
        fallback={
          <div className="app-loading-container">
            <OActivityIndicator size={50} />
          </div>
        }
      >
        <BrowserRouter>
          <Switch>
            {token && user?.tenantId && <Route path="/" component={Layout} />}
            {token && user?.tenantId && <Redirect to="/" />}
            {!token && <Route path="/reset-password" component={ResetPassword} />}
            {!token && <Route path="/set-password" component={SetPassword} />}
            {!token && (
              <Route path="/password-set-successfully" component={PasswordSetSuccessfully} />
            )}
            {!token && <Route path="/confirm-account" component={ConfirmAccount} />}
            {!token && <Route path="/download-apps" component={DownloadApps} />}
            {!token && <Route path="/register" component={Register} />}
            {!token && <Route path="/login" component={Login} />}
            {!token && <Redirect to="/login" />}
          </Switch>
        </BrowserRouter>
      </Suspense>

      <Snackbar snackbar={state.snackbar} />
      <Modal
        message={state?.modal?.message}
        buttonText={state?.modal?.buttonText}
        open={state?.modal?.open}
        buttonColor={state?.modal?.buttonColor}
      />
    </ThemeProvider>
  )
}

export default App
