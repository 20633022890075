import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'

export default function createMyTheme(options?: ThemeOptions) {
    return createMuiTheme({
        palette: {
            type: 'light',
            primary: {
                main: '#69AAFF'
            },
            secondary: {
                main: '#f06292'
            },
        },
        ...options,
    })
}
