import React, { createContext, useContext, useReducer } from "react"
import {
  SET_TOKEN,
  REMOVE_TOKEN,
  SET_SNACKBAR,
  SET_USER_INFO,
  REMOVE_USER_INFO,
  SET_MODAL,
  SET_PRIMARY_COLOR,
  REMOVE_PRIMARY_COLOR
} from "./actionTypes"
import { Store } from "../type/models/store"

const user = JSON.parse(localStorage.getItem("user") || "{}")

const initialState = {
  token: localStorage.getItem("token"),
  primaryColor: localStorage.getItem("primary_color") || "#69AAFF",
  snackbar: {},
  user: user,
  modal: {
    message: "",
    open: false,
    action: false,
    buttonText: "",
    buttonColor: [],
  },
  render: false,
} as any

interface IContextProps {
  state: Store
  dispatch: ({ type }: { type: string }) => void
}

const StoreContext = createContext({} as IContextProps)

const reducer = (state: typeof initialState, action: any) => {
  switch (action.type) {
    case SET_TOKEN:
      action.payload !== undefined && localStorage.setItem("token", action.payload)
      return {
        ...state,
        token: action.payload
      }
    case REMOVE_TOKEN:
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      return {
        token: null,
      }
    case SET_SNACKBAR:
      return {
        ...state,
        snackbar: action.payload,
      }
    case SET_USER_INFO:
      action.payload !== undefined && localStorage.setItem("user", JSON.stringify(action.payload))
      return {
        ...state,
        user: action.payload
      }
    case REMOVE_USER_INFO:
      localStorage.removeItem("user")
      return {
        ...state,
        user: null,
      }
    case SET_MODAL:
      return {
        ...state,
        modal: { ...action.payload },
      }
    case SET_PRIMARY_COLOR:
      action.payload !== undefined && localStorage.setItem("primary_color", action.payload)
      return {
        ...state,
        primaryColor: action.payload || "#69AAFF"
      }
    case REMOVE_PRIMARY_COLOR:
      localStorage.removeItem("primary_color")
      return {
        ...state,
        primaryColor: "#69AAFF"
      }
    default:
      return state
  }
}

export const StoreProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>
}

export const useStore = () => useContext(StoreContext)
