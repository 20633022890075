import React from "react"
import { Formik } from "formik"
import { setPrimaryColor, setTokenAction, setUserInfo } from "../../../store/actions"
import { useStore } from "../../../store/store"
import { LoginValues } from "./LoginValues.interface"
import useFetch from "../../../lib/useFetch"
import FormUserWrapper from "../../../common/FormUserWrapper/FormUserWrapper"
import OTextField from "../../../common/OTextField/OTextField"

import styles from "./Login.module.scss"
import { Link } from "react-router-dom"
import LoginLogo from "../../../assets/login-logo.png"

const Login = () => {
  const { dispatch } = useStore()
  const [fetchHook] = useFetch()

  const handleSubmit = async (values: LoginValues) => {
    const response = await fetchHook({
      path: `api/login/admin`,
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
    if (response?.success) {
      dispatch(
        setUserInfo({
          id: response.user.id,
          tenantId: response.user.tenantId,
          name: response.user.name,
          isAdmin: response.user.admin,
          superadmin: response.user.superadmin
        })
      )
      dispatch(setTokenAction(response.token))
      dispatch(setPrimaryColor(response.theme.primary_color))
    }
  }

  return (
    <div className={styles.login}>
      <div className={styles.loginBackground}>
        <div className={styles.logo}>
          <img src={LoginLogo}></img>
        </div>
      </div>
      <div className={styles.loginFormContainer}>
        <Formik
          enableReinitialize={true}
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors: any = {}
            if (!values.email) {
              errors.email = "Email is required"
            }
            if (!values.password) {
              errors.password = "Password is required"
            }
            return errors
          }}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FormUserWrapper
                title="Login"
                subtitle="Fill in your account information below"
                buttonText="Continue"
              >
                <div>
                  <OTextField
                    id="outlined-basic"
                    label="Email"
                    variant="filled"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.email && touched.email ? errors.email : ""}
                    error={errors.email !== "" && errors.email !== undefined && touched.email}
                    name="email"
                    className={styles.inputs}
                  />
                </div>
                <div>
                  <OTextField
                    type="password"
                    id="outlined-basic"
                    label="Password"
                    variant="filled"
                    value={values.password}
                    onChange={handleChange("password")}
                    onBlur={handleBlur}
                    helperText={errors.password && touched.password ? errors.password : ""}
                    error={errors.password && touched.password}
                    name="password"
                    className={styles.inputs}
                  />
                </div>

                <div className={styles.link}>
                  <Link to="/reset-password">Forgot your password?</Link>
                </div>
              </FormUserWrapper>
            </form>
          )}
        </Formik>
        <p className={styles.privacy}>By signing in, you agree to our terms and policy</p>
      </div>
    </div>
  )
}

export default Login
