import React from "react"
import { Formik } from "formik"
import { setSnackBar } from "../../../store/actions"
import { useStore } from "../../../store/store"
import { RegisterValues } from "./ResetPassword.interface"
import useFetch from "../../../lib/useFetch"
import OTextField from "../../../common/OTextField/OTextField"
import FormUserWrapper from "../../../common/FormUserWrapper/FormUserWrapper"

import styles from "./ResetPassword.module.scss"

const ResetPassword = () => {
  const { dispatch } = useStore()
  const [fetchHook] = useFetch()

  const handleSubmit = async (values: RegisterValues) => {
    const response = await fetchHook({
      path: `api/reset-password`,
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
    if (response?.success) {
      dispatch(
        setSnackBar({
          message: "An email was sent for you to confirm the reset password process.",
          severity: "success",
          open: true,
        })
      )
    } else {
      dispatch(
        setSnackBar({
          message: response?.message,
          severity: "error",
          open: true,
        })
      )
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.content}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              email: "",
            }}
            validate={(values) => {
              const errors: any = {}
              if (!values.email) {
                errors.email = "Email is required"
              }
              return errors
            }}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormUserWrapper
                  title="Reset password"
                  subtitle="Please enter your email address"
                  buttonText="Continue"
                >
                  <div>
                    <OTextField
                      id="outlined-basic"
                      label="Email"
                      variant="filled"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.email && touched.email ? errors.email : ""}
                      error={errors.email !== "" && errors.email !== undefined && touched.email}
                      name="email"
                      className={styles.inputs}
                    />
                  </div>
                </FormUserWrapper>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
