import React, { useState } from "react"

import { Button } from "@material-ui/core"

const ButtonComponent = ({ text, variant, disabled, style, hsl, ...rest }: any) => {
  const [hover, setHover] = useState(false)
  const buttonColors =
    hsl && !disabled
      ? variant === "contained"
        ? hover
          ? {
              backgroundColor: `hsl(${hsl[0]},${hsl[1] - 10}%,${hsl[2] - 15}%)`,
              color: "white",
            }
          : { backgroundColor: `hsl(${hsl[0]},${hsl[1]}%,${hsl[2]}%)`, color: "white" }
        : hover
        ? {
            borderColor: `hsl(${hsl[0]},${hsl[1]}%,${hsl[2]}%)`,
            backgroundColor: `hsl(${hsl[0]},${hsl[1]}%,${hsl[2]}%,0.05)`,
            color: `hsl(${hsl[0]},${hsl[1]}%,${hsl[2]}%)`,
          }
        : {
            borderColor: `hsl(${hsl[0]},${hsl[1]}%,${hsl[2]}%)`,
            color: `hsl(${hsl[0]},${hsl[1]}%,${hsl[2]}%)`,
          }
      : {}

  return (
    <Button
      {...rest}
      disabled={disabled}
      variant={variant}
      style={{
        ...buttonColors,
        height: 35,
        ...style,
      }}
      onMouseEnter={() => setHover(!hover)}
      onMouseLeave={() => setHover(!hover)}
      disableElevation={true}
      size="small"
    >
      {text}
    </Button>
  )
}

export default ButtonComponent
