import React from "react"
import styles from "./FormUserWrapper.module.scss"

import { Button } from "@material-ui/core"

const FormUserWrapper = ({ title, subtitle, buttonText, children }: any) => {
  return (
    <div className={`o-card ${styles.userForm}`}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
      {children}
      <div className={styles.button}>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          style={{
            width: "100%",
            color: "white",
          }}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default FormUserWrapper
