import React from "react"
import styles from "./DownloadApps.module.scss"
import app_store from "../../../assets/play_store.png"
import google_play from "../../../assets/google_play.png"
import { Typography } from "@material-ui/core"

const DownloadApps = () => {
  return (
    <div className={styles.container}>
      <Typography variant="h4" className={styles.title}>
        Download Now!
      </Typography>
      <div className={styles.apps}>
        <img src={app_store} className={styles.img} />
        <img src={google_play} className={styles.img} />
      </div>
    </div>
  )
}

export default DownloadApps
