import {
  SET_TOKEN,
  REMOVE_TOKEN,
  SET_SNACKBAR,
  SET_USER_INFO,
  REMOVE_USER_INFO,
  SET_MODAL,
  SET_PRIMARY_COLOR,
  REMOVE_PRIMARY_COLOR
} from "./actionTypes"

export const setTokenAction = (payload: any) => {
  return {
    type: SET_TOKEN,
    payload,
  }
}

export const removeTokenAction = () => {
  return {
    type: REMOVE_TOKEN,
  }
}

export const setSnackBar = (payload: any) => {
  return {
    type: SET_SNACKBAR,
    payload,
  }
}

export const setUserInfo = (payload: any) => {
  return {
    type: SET_USER_INFO,
    payload,
  }
}

export const removeUserInfo = () => {
  return {
    type: REMOVE_USER_INFO,
  }
}

export const setModal = (payload: any) => {
  return {
    type: SET_MODAL,
    payload,
  }
}

export const setPrimaryColor = (payload: any) => {
  return {
    type: SET_PRIMARY_COLOR,
    payload,
  }
}

export const removePrimaryColor = () => {
  return {
    type: REMOVE_PRIMARY_COLOR,
  }
}
