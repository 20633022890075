import React from "react"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    color: "#7F8292",
    "&.MuiFilledInput-root": {
      color: "#2C2C2C",
      borderRadius: 4,
      backgroundColor: "#F6F7FA",
      "&.MuiFilledInput-underline:before": {
        borderBottom: 0,
      },
      "&.MuiFilledInput-underline:after": {
        borderBottom: 0,
      },
    }
  }
}));

const OTextField = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TextField
        {...props}
        InputProps={{ className: classes.root }}
      />
    </div>
  )
}

export default OTextField
