import React from "react"
import { Formik } from "formik"
import { setSnackBar } from "../../../store/actions"
import { useStore } from "../../../store/store"
import { SetPasswordValues } from "./SetPassword.interface"
import useFetch from "../../../lib/useFetch"
import FormUserWrapper from "../../../common/FormUserWrapper/FormUserWrapper"
import OTextField from "../../../common/OTextField/OTextField"

import styles from "./SetPassword.module.scss"

const SetPassword = ({ history }: any) => {
  const { dispatch } = useStore()
  const [fetchHook] = useFetch()

  let search = window.location.search
  let params = new URLSearchParams(search)

  const { workspace, email, resetToken } = getQueryParams(params)

  const handleSubmit = async (values: SetPasswordValues) => {
    values = {
      ...values,
      email,
      resetToken,
    }
    const response = await fetchHook({
      path: `api/set-password`,
      method: "PUT",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        "X-TENANT-ID": workspace,
      },
    })
    if (response?.success) {
      if (!response?.payload?.admin) {
        return history.push("/password-set-successfully")
      }
      history.push("/login")
    } else {
      dispatch(
        setSnackBar({
          message: response?.message,
          severity: "error",
          open: true,
        })
      )
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.content}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              email: "",
              password: "",
              resetToken: "",
            }}
            validate={(values) => {
              const errors: any = {}

              if (!values.password) {
                errors.password = "Password is required"
              }

              if (values.password.length >= 255) {
                errors.password = "Password should be lower than 255 characters"
              }

              if (values.password.length < 8) {
                errors.password = "Password should be 8 characters"
              }

              return errors
            }}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormUserWrapper
                  title="Set password"
                  subtitle="Please enter your new password"
                  buttonText="set password"
                >
                  <div>
                    <OTextField
                      type="password"
                      id="outlined-basic"
                      label="Password"
                      variant="filled"
                      value={values.password}
                      onChange={handleChange("password")}
                      onBlur={handleBlur}
                      helperText={errors.password && touched.password ? errors.password : ""}
                      error={errors.password && touched.password}
                      name="password"
                      className={styles.inputs}
                    />
                  </div>
                </FormUserWrapper>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default SetPassword

function getQueryParams(params: any): any {
  return {
    workspace: params.get("workspace"),
    email: params.get("email"),
    resetToken: params.get("resetToken"),
  }
}
