import React from "react"
import { CheckIcon } from "../../../assets/SvgIcons"
import { Card, CardContent, CardHeader } from "@material-ui/core"
import styles from "./PasswordSetSuccessfully.module.scss"

const PasswordSetSuccessfully = () => {
  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <CardHeader title="Password Changed" />
        <div className={styles.icon}>
          <CheckIcon />{" "}
        </div>
        <CardContent className={styles.content}>Your password has been changed.</CardContent>
      </Card>
    </div>
  )
}

export default PasswordSetSuccessfully
