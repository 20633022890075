import React from "react"

export const DashboardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.47" height="27.47" viewBox="0 0 27.47 27.47">
      <path
        id="Icon_material-dashboard"
        data-name="Icon material-dashboard"
        d="M4.5,19.206H16.265V4.5H4.5Zm0,11.765H16.265V22.147H4.5Zm14.706,0H30.97V16.265H19.206Zm0-26.47v8.823H30.97V4.5Z"
        transform="translate(-4 -4)"
        fill="none"
        stroke="#151615"
        strokeWidth="1"
      />
    </svg>
  )
}

export const PostsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.975"
      height="24.231"
      viewBox="0 0 31.975 24.231"
    >
      <path
        id="Icon_awesome-image"
        data-name="Icon awesome-image"
        d="M28.071,27.731H2.9a2.9,2.9,0,0,1-2.9-2.9V7.4A2.9,2.9,0,0,1,2.9,4.5H28.071a2.9,2.9,0,0,1,2.9,2.9V24.827A2.9,2.9,0,0,1,28.071,27.731ZM6.776,7.888a3.388,3.388,0,1,0,3.388,3.388A3.388,3.388,0,0,0,6.776,7.888Zm-2.9,15.972H27.1V17.084l-5.295-5.295a.726.726,0,0,0-1.027,0l-8.2,8.2L9.225,16.629a.726.726,0,0,0-1.027,0L3.872,20.956Z"
        transform="translate(0.5 -4)"
        fill="none"
        stroke="#000"
        strokeWidth="1"
      />
    </svg>
  )
}

export const BookingIcon = ({
  width = "27.518",
  height = "29.135",
  stroke = "#151615",
  strokeWidth = "1",
}: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27.518 29.135"
    >
      <g id="Group_582" data-name="Group 582" transform="translate(0.5 0.5)">
        <rect
          id="Rectangle_1107"
          data-name="Rectangle 1107"
          width="26.518"
          height="24.793"
          rx="1"
          transform="translate(0 3.342)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <g id="Group_178" data-name="Group 178" transform="translate(6.791)">
          <line
            id="Line_7"
            data-name="Line 7"
            y2="6.683"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <line
            id="Line_8"
            data-name="Line 8"
            y2="6.683"
            transform="translate(12.936)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
        </g>
        <line
          id="Line_9"
          data-name="Line 9"
          x2="26.518"
          transform="translate(0 14.768)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <g id="Group_179" data-name="Group 179" transform="translate(6.935 20.517)">
          <line
            id="Line_10"
            data-name="Line 10"
            x2="2.013"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
          <line
            id="Line_11"
            data-name="Line 11"
            x2="5.929"
            transform="translate(6.719)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          />
        </g>
      </g>
    </svg>
  )
}

export const ChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.224"
      height="27.853"
      viewBox="0 0 28.224 27.853"
    >
      <g id="Group_583" data-name="Group 583" transform="translate(0.5 0.5)">
        <g id="Group_202" data-name="Group 202" transform="translate(0 0)">
          <g id="Group_183" data-name="Group 183" transform="translate(5.488 4.26)">
            <g id="Group_200" data-name="Group 200" transform="translate(0 0)">
              <line
                id="Line_14"
                data-name="Line 14"
                x2="4.057"
                transform="translate(0 0)"
                fill="none"
                stroke="#151615"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <line
                id="Line_15"
                data-name="Line 15"
                x2="8.113"
                transform="translate(0 5.086)"
                fill="none"
                stroke="#151615"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
          </g>
          <g
            id="Icon_ionic-ios-chatboxes"
            data-name="Icon ionic-ios-chatboxes"
            transform="translate(0 0)"
          >
            <path
              id="Path_32"
              data-name="Path 32"
              d="M35.435,11.981H15.17a3.489,3.489,0,0,0-3.484,3.484v14.11A3.481,3.481,0,0,0,15.17,33.05h11.6a1,1,0,0,1,.695.293l5.761,5.313c.32.311.85.183.85-.265V33.764c0-.549.347-.722.9-.722h.091a3.806,3.806,0,0,0,3.85-3.475v-14.1A3.47,3.47,0,0,0,35.435,11.981Z"
              transform="translate(-11.686 -11.981)"
              fill="none"
              stroke="#151615"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.251"
      height="27.211"
      viewBox="0 0 23.251 27.211"
    >
      <g id="Group_581" data-name="Group 581" transform="translate(0.5 0.5)">
        <ellipse
          id="Ellipse_71"
          data-name="Ellipse 71"
          cx="5.727"
          cy="5.727"
          rx="5.727"
          ry="5.727"
          transform="translate(5.398)"
          fill="none"
          stroke="#151615"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_75"
          data-name="Path 75"
          d="M220.014,417.582a11.125,11.125,0,0,0-11.125,11.125H231.14A11.125,11.125,0,0,0,220.014,417.582Z"
          transform="translate(-208.889 -402.497)"
          fill="none"
          stroke="#151615"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}

export const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.646"
      height="16.427"
      viewBox="0 0 11.646 16.427"
    >
      <g id="Group_726" data-name="Group 726" transform="translate(15.488 17.039)">
        <path
          id="Icon_material-location-on"
          data-name="Icon material-location-on"
          d="M12.748,3A5.244,5.244,0,0,0,7.5,8.248c0,3.936,5.248,9.746,5.248,9.746S18,12.184,18,8.248A5.244,5.244,0,0,0,12.748,3Zm0,7.122a1.874,1.874,0,1,1,1.874-1.874A1.875,1.875,0,0,1,12.748,10.122Z"
          transform="translate(-22.413 -19.464)"
          fill="none"
          stroke="#1976d2"
          strokeWidth="1.15"
        />
      </g>
    </svg>
  )
}

export const CheckIcon = () => {
  return (
    <svg
      id="checked_1_"
      data-name="checked (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 512 512"
    >
      <g id="Group_865" data-name="Group 865">
        <g id="Group_864" data-name="Group 864">
          <path
            id="Path_3222"
            data-name="Path 3222"
            d="M497.36,70a19.292,19.292,0,0,0-27.285-.032L238.582,300.845,155.06,210.132a19.3,19.3,0,1,0-28.392,26.14l97.126,105.481a19.273,19.273,0,0,0,13.784,6.22c.141.006.277.006.412.006a19.317,19.317,0,0,0,13.623-5.628L497.322,97.286A19.294,19.294,0,0,0,497.36,70Z"
            fill="#0aa058"
          />
        </g>
      </g>
      <g id="Group_867" data-name="Group 867">
        <g id="Group_866" data-name="Group 866">
          <path
            id="Path_3223"
            data-name="Path 3223"
            d="M492.7,236.7a19.3,19.3,0,0,0-19.3,19.3c0,119.883-97.524,217.407-217.407,217.407S38.593,375.883,38.593,256,136.124,38.593,256,38.593A19.3,19.3,0,1,0,256,0C114.84,0,0,114.84,0,256S114.84,512,256,512,512,397.154,512,256A19.3,19.3,0,0,0,492.7,236.7Z"
            fill="#0aa058"
          />
        </g>
      </g>
    </svg>
  )
}

export const DeclineIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 329.27 329.263">
      <path
        id="close"
        d="M194.8,164.77,323.012,36.555A21.329,21.329,0,0,0,292.848,6.391L164.633,134.605,36.422,6.391A21.329,21.329,0,0,0,6.258,36.555L134.469,164.77,6.258,292.984a21.329,21.329,0,1,0,30.164,30.164L164.633,194.934,292.848,323.148a21.329,21.329,0,0,0,30.164-30.164Zm0,0"
        transform="translate(0 -0.136)"
        fill="#e30404"
      />
    </svg>
  )
}

export const tagIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 11.57 11.57">
    <path
      id="Icon_metro-tags"
      data-name="Icon metro-tags"
      d="M20.242,7.716H15.817a1.539,1.539,0,0,0-.944.391L9.387,13.593a.555.555,0,0,0,0,.782l4.749,4.749a.555.555,0,0,0,.782,0L20.4,13.638a1.539,1.539,0,0,0,.391-.944V8.269A.555.555,0,0,0,20.242,7.716Zm-2.766,4.425a1.106,1.106,0,1,1,1.106-1.106A1.106,1.106,0,0,1,17.477,12.141Z"
      transform="translate(-9.226 -7.716)"
      fill="#969f96"
    />
  </svg>
)
