import { useCallback, useState } from "react"
import { useStore } from "../store/store"
import { setSnackBar, removeTokenAction } from "../store/actions"
import { useHistory } from "react-router-dom"

const url = process.env.REACT_APP_URI

const useFetch = () => {
  const { state, dispatch } = useStore()
  const history = useHistory()
  const { user, token } = state

  const defaultHeaders = {
    "X-TENANT-ID": user?.tenantId,
    "X-USER-ID": user?.id,
    Authorization: "Bearer " + token,
  }
  const [loading, setLoading] = useState(false)

  const fetchHook = useCallback(
    async ({ path, method = "GET", body = null, headers = {} }: any) => {
      setLoading(true)
      try {
        const response = await fetch(`${url}/${path}`, {
          method,
          body,
          headers: { ...defaultHeaders, ...headers },
        })

        if (response.status === 401) {
          dispatch(removeTokenAction())
          history.push("/login")
        }

        const responseData = await response.json()
        if (responseData.message) {
          if (responseData.success) {
            dispatch(
              setSnackBar({ message: responseData.message, severity: "success", open: true })
            )
          } else {
            dispatch(
              setSnackBar({
                message: responseData.message,
                severity: "error",
                open: true,
              })
            )
          }
        }

        return responseData
      } catch (err) {
        dispatch(
          setSnackBar({
            message: err?.message || "Something went wrong.",
            severity: "error",
            open: true,
          })
        )
        throw err
      } finally {
        setLoading(false)
      }
    },
    []
  )
  return [fetchHook, loading, defaultHeaders] as const
}

export default useFetch
