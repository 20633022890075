import React from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function CustomizedSnackbar({ snackbar }: any) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(snackbar?.open)

  React.useEffect(() => {
    setOpen(snackbar?.open)
  }, [snackbar])

  const handleClose = (event: object, reason: string) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }
  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackbar?.severity}>
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </div>
  )
}
