import React from "react"
import styles from "./Modal.module.scss"
import { useStore } from "../../store/store"
import { setModal } from "../../store/actions"
import ButtonComponent from "../../components/Elements/Button/Button"
import { Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined"

const Modal = ({ message, open }: any) => {
  const { dispatch } = useStore()

  const closeModal = () => {
    dispatch(setModal({ open: false }))
  }

  const action = () => {
    dispatch(setModal({ action: true }))
  }

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='lg'
        >
          <div className={styles.modal}>
            <div className={styles.header}>
              <div className={styles.title}>
                <WarningOutlinedIcon style={{ color: "#FFCC00", marginRight: 10 }} />
                <h2>Confirm</h2>
              </div>
              <CloseIcon onClick={closeModal} className={styles.closeIcon} />
            </div>
            <div className={styles.modalText}>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
              </DialogContent>
              <DialogActions className={styles.action}>
                <ButtonComponent variant="outlined" onClick={action} text="Yes, I Confirm" />
              </DialogActions>
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}

export default Modal
