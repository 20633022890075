import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import { setSnackBar } from "../../../store/actions"
import { useStore } from "../../../store/store"
import { RegisterValues } from "./Register.interface"
import useFetch from "../../../lib/useFetch"

import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import styles from "./Register.module.scss"

const Register = ({ history }: any) => {
  const [showPassword, setShowPassword] = useState(false)
  const [tenant, setTenant] = useState<any>(null)
  const { dispatch } = useStore()
  const [fetchHook] = useFetch()

  useEffect(() => {
    fetchTenant()
  }, [])

  let search = window.location.search
  let params = new URLSearchParams(search)

  const { email, phone, workspace, confirmationToken, invitationToken } = getQueryParams(params)

  const handleSubmit = async (values: RegisterValues) => {
    const [firstName, lastName = ""] = values.fullName.split(" ")
    values = {
      ...values,
      firstName,
      lastName,
      tenant_id: workspace as string,
      confirmationToken: confirmationToken as string,
      invitationToken: invitationToken as string,
      email: (email || values.email?.trim() || null) as any,
      phone: (phone || values.phone?.trim() || null) as any,
    }
    const response = await fetchHook({
      path: `api/register`,
      method: "PUT",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        "X-TENANT-ID": workspace,
      },
    })
    if (response?.success) {
      history.push("/download-apps")
    } else {
      dispatch(
        setSnackBar({
          message: response?.message,
          severity: "error",
          open: true,
        })
      )
    }
  }

  const fetchTenant = async () => {
    const response = await fetchHook({ path: `api/tenants/${workspace}` })
    if (response?.success) {
      setTenant(response.payload)
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <CardContent className={styles.content}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              slug: "",
              phone: "",
              email: "",
              password: "",
              firstName: "",
              lastName: "",
              fullName: "",
              tenant_id: "",
              confirmPassword: "",
              invitationToken: "",
              confirmationToken: "",
              username: "",
            }}
            validate={(values) => {
              const errors: any = {}
              values.slug = tenant.slug
              if (!values.slug) {
                errors.slug = "Workspace is required"
              }
              if (!values.fullName) {
                errors.fullName = "Full Name is required"
              }
              if (!values.password) {
                errors.password = "Password is required"
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = "Password is required"
              } else if (
                values.password &&
                values.confirmPassword &&
                values.password !== values.confirmPassword
              ) {
                errors.confirmPassword = "Password and Confirm Password must be match."
              }
              return errors
            }}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} className={styles.registerForm}>
                <Typography variant="h3" className={styles.typography}>
                  Register
                </Typography>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    disabled
                    value={tenant?.slug}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="slug"
                    className={styles.inputs}
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.fullName && touched.fullName ? errors.fullName : ""}
                    error={
                      errors.fullName !== "" && errors.fullName !== undefined && touched.fullName
                    }
                    name="fullName"
                    className={styles.inputs}
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    label=" Email"
                    variant="outlined"
                    value={email || values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.email && touched.email ? errors.email : ""}
                    error={errors.email !== "" && errors.email !== undefined && touched.email}
                    name="email"
                    className={styles.inputs}
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    value={phone || values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="phone"
                    className={styles.inputs}
                  />
                </div>
                <div>
                  <FormControl variant="outlined" className={styles.inputs}>
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      error={
                        errors.password !== "" && errors.password !== undefined && touched.password
                      }
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange("password")}
                      error={
                        errors.password !== "" && errors.password !== undefined && touched.password
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                    {errors.password && touched.password && (
                      <FormHelperText error={true}>Password is required</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div>
                  <FormControl variant="outlined" className={styles.inputs}>
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      error={
                        errors.confirmPassword !== "" &&
                        errors.confirmPassword !== undefined &&
                        touched.confirmPassword
                      }
                    >
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={values.confirmPassword}
                      onChange={handleChange("confirmPassword")}
                      error={
                        errors.confirmPassword !== "" &&
                        errors.confirmPassword !== undefined &&
                        touched.confirmPassword
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={130}
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <FormHelperText error={true}>{errors.confirmPassword}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <CardActions className={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.button}
                    type="submit"
                  >
                    Register
                  </Button>
                </CardActions>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  )
}

export default Register

function getQueryParams(params: any): any {
  return {
    email: params.get("email"),
    phone: params.get("phone"),
    workspace: params.get("workspace"),
    confirmationToken: params.get("confirmationToken"),
    invitationToken: params.get("invitationToken"),
  }
}
